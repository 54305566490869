import { Box, Button } from "@material-ui/core";
import { throttle } from "lodash-es";
import { useCallback, useEffect, useRef, useState } from "react";
import slugify from "slugify";
import styled from "styled-components";

import { IBookingIId } from "data/backoffice/bookings/types";
import { IExperience } from "data/experiences/types";

import useErrors from "hooks/useErrors";
import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import bookingsService from "services/api/bookings";
import experiencesService from "services/api/experiences";

import useLanguages from "store/hooks/useLanguages";

import { DATE_FORMATS, getDateWithDefaultTime, parseISOWrapper } from "utils/dates";
import { prices } from "utils/prices";
import fillRoute from "utils/routes";

import ElemClipboard from "components/backoffice/ElemClipboard";
import DashboardSeeDetailsTraveler from "components/dashboard/DashboardSeeDetailsTraveler";
import AppWrapper from "components/layout/AppWrapper";

import Breadcrumbs from "ui/Breadcrumbs";
import ChildrenLoader from "ui/loaders/ChildrenLoader";
import CircularLoader from "ui/loaders/CircularLoader";
import Table, { Order } from "ui/Table";

import colors from "styles/colors";
import { Container, StyledExternalLink, StyledLink } from "styles/common";

import { parseStatus } from "../../account/myBookings/utils";
import { Header, Wrapper } from "../../account/shared.styled";
import FilterSearchPartnerBookings, { ISelectedFilters } from "./components/FilterSearch";
import { columns, fields, idForContainer, listItemsOffset, sortedColumns } from "./config";

const StyledButton = styled(Button)`
	background-color: ${colors.borderGray};
	color: rgba(0, 0, 0, 0.87);
`;

interface IQueryParams {
	min_total_price?: number;
	max_total_price?: number;
	date_time_from?: string;
	date_time_to?: string;
}

const PartnerBookingsPage = () => {
	const { t } = useTranslation(["common", "ui"]);

	const { handleAndNotify } = useErrors();

	const { findNameByKey } = useLanguages();

	const [results, setResults] = useState<{ data: IBookingIId[]; count: number }>();
	const [page, setPage] = useState<number>(1);
	const [loadMore, setLoadMore] = useState<boolean>(false);
	const [loader, setLoader] = useState<boolean>(false);
	const [orderData, setOrderData] = useState<{ sort: string; order: Order; orderBy: number }>();
	const [queryParams, setQueryParams] = useState<IQueryParams>();

	const [selectedBooking, setSelectedBooking] = useState<IBookingIId | null>(null);
	const [selectedExperience, setSelectedExperience] = useState<IExperience | null>(null);

	const preventLoading = useRef<boolean>(false);

	useEffect(() => {
		const mainContainerNode = document.querySelector(`#${idForContainer}`);

		const handleScroll = throttle(() => {
			if (mainContainerNode) {
				if (document.documentElement.scrollTop >= mainContainerNode.scrollHeight - 600) {
					if (!preventLoading.current) {
						setLoadMore(true);

						preventLoading.current = true;
					}
				}
			}
		}, 1000);

		document.addEventListener("scroll", handleScroll);

		return () => {
			document.removeEventListener("scroll", handleScroll);
		};

		// eslint-disable-next-line
	}, []);

	const fetchData = useCallback(
		async (data?: object, reset?: boolean) => {
			try {
				const bookings = await bookingsService.fetchBookings({
					...(orderData && !reset && { sort: orderData.sort, order: orderData.order }),
					...(!reset && queryParams),
					...data,
				});

				setResults(prevState => ({
					count: bookings.count,
					data: !!prevState?.data ? [...prevState.data, ...bookings.data] : bookings.data,
				}));
			} catch (e) {
				handleAndNotify(e);
			} finally {
				preventLoading.current = false;

				setLoadMore(false);
			}
		},

		// eslint-disable-next-line
		[orderData, queryParams],
	);

	useEffect(() => {
		fetchData();

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		const loadMoreData = async () => {
			setLoader(false);

			if (loadMore) {
				if (page * listItemsOffset <= (results?.count || 0)) {
					setLoader(true);

					await fetchData({ offset: page * listItemsOffset });

					setPage(prevState => prevState + 1);

					setLoadMore(false);
				}
			}
		};

		loadMoreData();

		// eslint-disable-next-line
	}, [loadMore]);

	const sortData = (nameField: string, order: Order, orderBy: number) => {
		setPage(1);

		setResults(undefined);

		setOrderData({ sort: nameField, order, orderBy });

		fetchData({ sort: nameField, order });
	};

	const getFilters = (filters: ISelectedFilters) => {
		const queryParamsFromFilters: IQueryParams = {};

		Object.entries(filters).forEach(([filterType, filterValue]) => {
			if (!filterValue) {
				return;
			}

			if (filterType === "dateTime") {
				if (filterValue[0]) {
					queryParamsFromFilters.date_time_from = getDateWithDefaultTime(filterValue[0], "FROM");
				}
				if (filterValue[1]) {
					queryParamsFromFilters.date_time_to = getDateWithDefaultTime(filterValue[1], "TO");
				}
			} else if (filterType === "price") {
				if (filterValue.from) {
					queryParamsFromFilters.min_total_price = filterValue.from;
				}
				if (filterValue.to) {
					queryParamsFromFilters.max_total_price = filterValue.to;
				}
			}
		});

		setOrderData(undefined);
		setPage(1);
		setResults(undefined);

		setQueryParams(queryParamsFromFilters);

		fetchData(queryParamsFromFilters, true);
	};

	const openDetails = async (id: string, eid: string) => {
		setSelectedBooking(results?.data?.find(elem => elem.id === id) || null);

		try {
			const expDetails = await experiencesService.getExperienceById(eid);

			setSelectedExperience(expDetails);
		} catch (e) {
			setSelectedExperience(null);

			handleAndNotify(e);
		}
	};

	const parsedData =
		results?.data?.map(elem => ({
			id: <ElemClipboard splitPart={15} elem={elem.id} />,
			status: (
				<Box display="inline" fontWeight="bold" color={parseStatus(elem.status).color}>
					{parseStatus(elem.status).label}
				</Box>
			),
			exp_title: (
				<StyledLink
					to={fillRoute(paths.SEARCH_EXPERIENCES, {
						title: slugify(elem.exp_title?.toLowerCase() || ""),
						id: elem.eid,
					})}
				>
					{elem.exp_title}
				</StyledLink>
			),
			city_country_HELPER: (
				<StyledLink
					to={fillRoute(paths.SEARCH_COUNTRY_CITY, {
						country: elem.country,
						city: elem.city,
					})}
				>
					{elem.city}, {elem.country}
				</StyledLink>
			),
			date_time: parseISOWrapper(elem.date_time, DATE_FORMATS.DATETIME_AMPM_FORMAT),
			price: (
				<strong>
					{prices(elem.price, elem.currency !== "N/A" ? elem.currency : undefined, undefined, undefined, true)}
				</strong>
			),
			traveler_details: elem.traveler_details?.first_name ? (
				<Box display="flex" flexDirection="column" gridGap="5px">
					<Box fontWeight="500" fontSize="14px">
						{elem.traveler_details.first_name} {elem.traveler_details.last_name}
					</Box>

					{elem.traveler_details.phone && (
						<Box>
							<StyledExternalLink color="inherit" href={`tel:${elem.traveler_details.phone}`}>
								{elem.traveler_details.phone}
							</StyledExternalLink>
						</Box>
					)}

					{elem.traveler_details.email && (
						<Box>
							<StyledExternalLink color="inherit" href={`mailto:${elem.traveler_details.email}`}>
								{elem.traveler_details.email}
							</StyledExternalLink>
						</Box>
					)}
				</Box>
			) : (
				<i>{t("DASHBOARD_BOOKING_DETAILS_PREVIEW.NO_TRAVELER_DATA")}</i>
			),
			action_HELPER: (
				<Box display="flex" width="100%" justifyContent="center">
					<StyledButton size="small" onClick={() => openDetails(elem.id, elem.eid)}>
						{t("PARTNER_BOOKINGS.SHOW_DETAILS_BUTTON")}
					</StyledButton>
				</Box>
			),
		})) || [];

	return (
		<AppWrapper>
			<DashboardSeeDetailsTraveler
				id={selectedBooking?.id}
				avatarUrlLocalhost={selectedBooking?.localhost_details?.photo_url || undefined}
				title={selectedBooking?.exp_title || ""}
				startDate={selectedBooking?.date_time}
				startLocation={selectedExperience?.location?.start?.formatted_address || "-"}
				tickets={selectedBooking?.tickets}
				language={findNameByKey(selectedBooking?.language) || "-"}
				link={
					selectedExperience?.id
						? fillRoute(paths.SEARCH_EXPERIENCES, {
								title: slugify(selectedExperience.title.toLowerCase() || ""),
								id: selectedExperience.id,
						  })
						: ""
				}
				showDialog={!!selectedBooking}
				handleClose={() => {
					setSelectedBooking(null);
					setSelectedExperience(null);
				}}
				localhostLink={
					selectedBooking?.localhost_id &&
					selectedBooking?.localhost_details?.first_name &&
					fillRoute(paths.PUBLIC_PROFILE, {
						userName: encodeURI(selectedBooking.localhost_details.first_name.toLowerCase()),
						uid: selectedBooking.localhost_id,
					})
				}
				firstNameLocalhost={selectedBooking?.localhost_details?.first_name || null}
				phoneNumberLocalhost={selectedBooking?.localhost_details?.phone || null}
				mainPhoto={selectedExperience?.photo_main}
				experienceTime={selectedExperience?.duration_minutes}
				experienceTimeZone={selectedExperience?.location?.start?.timezone}
				comment={selectedBooking?.comments}
				status={selectedBooking?.status}
				statusLabel={
					selectedBooking?.status && (
						<Box display="inline" color={parseStatus(selectedBooking.status).color}>
							{parseStatus(selectedBooking.status).label}
						</Box>
					)
				}
				cost={prices(selectedBooking?.price, selectedExperience?.pricing.currency, undefined, undefined, true)}
				downloadTicketButton
			/>

			<Container>
				<Breadcrumbs lastBreadcrumbLabel={t("TOPBAR.PARTNER_BOOKINGS")} />

				<Wrapper>
					<Header>{t("TOPBAR.PARTNER_BOOKINGS")}</Header>

					<Box marginTop="80px">
						<Box marginBottom="30px">
							<FilterSearchPartnerBookings applySelectedFiltersAction={getFilters} />
						</Box>

						<Box id={idForContainer} style={{ overflowX: "auto" }}>
							<ChildrenLoader check={results}>
								<Table
									data={parsedData}
									columns={columns}
									fields={fields}
									sortedColumns={sortedColumns}
									externalSort={sortData}
									initialOrder={orderData?.order}
									initialOrderBy={orderData?.orderBy}
									totalRecords={results?.count}
								/>
							</ChildrenLoader>
						</Box>

						{loader && (
							<Box marginTop="50px">
								<CircularLoader absolute={false} />
							</Box>
						)}
					</Box>
				</Wrapper>
			</Container>
		</AppWrapper>
	);
};

export default PartnerBookingsPage;
