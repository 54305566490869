import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import { format } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import slugify from "slugify";

import { IBookingIId } from "data/backoffice/bookings/types";
import { IExperience } from "data/experiences/types";

import useErrors from "hooks/useErrors";
import useQuery from "hooks/useQuery";
import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import bookingsService from "services/api/bookings";
import experiencesService from "services/api/experiences";

import useLanguages from "store/hooks/useLanguages";

import { DATE_FORMATS, getDateWithDefaultTime, parseISOWrapper } from "utils/dates";
import { prices } from "utils/prices";
import fillRoute from "utils/routes";

import DashboardDetailsBox from "components/dashboard/DashboardDetailsBox";
import DashboardItemList from "components/dashboard/DashboardItemList";
import DashboardSeeAllList from "components/dashboard/DashboardSeeAllList";
import DashboardSeeDetailsTraveler from "components/dashboard/DashboardSeeDetailsTraveler";

import Calendar from "ui/forms/date-time/Calendar";

import colors from "styles/colors";

import { initialResults, listItemsLimit } from "../../config";
import { TKindOfList } from "../../types";
import { parseStatus } from "../../utils";
import Loader from "../Loader";
import {
	ItemWrapper,
	Row,
	StyledBrightness1,
	StyledDividerMain,
	StyledDividerSection,
	Text,
	TitleSection,
} from "./styled";

interface IResults {
	data: IBookingIId[];
	count: number;
}

const MyBookingsTraveler = () => {
	const { t } = useTranslation();

	const { handleAndNotify } = useErrors();

	const { findNameByKey } = useLanguages();

	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

	const query = useQuery();
	const bok = query.get("bok");

	const [resultsToday, setResultsToday] = useState<IResults>(initialResults);
	const [resultsPast, setResultsPast] = useState<IResults>(initialResults);
	const [resultsFuture, setResultsFuture] = useState<IResults>(initialResults);
	const [resultsSelectedDay, setResultsSelectedDay] = useState<IResults>(initialResults);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [showSuperBookingDetails, setShowSuperBookingDetails] = useState<boolean>(false);
	const [showAllList, setShowAllList] = useState<boolean>(false);
	const [kindOfList, setKindOfList] = useState<TKindOfList>("TODAY");
	const [selectedBooking, setSelectedBooking] = useState<IBookingIId | null>(null);
	const [selectedExperience, setSelectedExperience] = useState<IExperience | null>(null);
	const [datesList, setDatesList] = useState<string[]>([]);
	const [currentMonth, setCurrentMonth] = useState<number>(new Date().getMonth() + 1);
	const [currentYear, setCurrentYear] = useState<number>(new Date().getFullYear());
	const [selectedDayCalendar, setSelectedDayCalendar] = useState<string | boolean>(false);
	const [forceReload, setForceReload] = useState<number>(new Date().getTime());

	useEffect(() => {
		if (bok) {
			const fetchBookingFromUrlParam = async () => {
				try {
					const booking = await bookingsService.getBooking(bok);

					if (booking?.data) {
						showBookingDetails(booking.data);
					}
				} catch (e) {
					handleAndNotify(t("MY_BOOKINGS.BOOKINGS_WRONG_URL_PARAM"));
				}
			};

			fetchBookingFromUrlParam();
		}

		// eslint-disable-next-line
	}, [bok]);

	useEffect(() => {
		const getSuperbookingsSelectedDay = async () => {
			try {
				if (selectedDayCalendar) {
					const selectedDayFrom = getDateWithDefaultTime(new Date(selectedDayCalendar as string), "FROM");
					const selectedDayTo = getDateWithDefaultTime(new Date(selectedDayCalendar as string), "TO");

					const bookings = await bookingsService.fetchBookings({
						limit: listItemsLimit,
						date_time_from: selectedDayFrom,
						date_time_to: selectedDayTo,
						order: "asc",
						sort: "utc_date_time",
					});

					if (bookings) {
						setResultsSelectedDay(bookings);
					}
				}
			} catch (e) {
				handleAndNotify(e);
			}
		};

		getSuperbookingsSelectedDay();

		// eslint-disable-next-line
	}, [selectedDayCalendar]);

	useEffect(() => {
		const createDatesList = async () => {
			try {
				let firstDay = `${currentYear}-${currentMonth}-01`;
				if (currentMonth < 10) {
					firstDay = `${currentYear}-0${currentMonth}-01`;
				}
				const firstDayFrom = getDateWithDefaultTime(new Date(firstDay), "FROM");

				const bookingsList = await bookingsService.fetchBookings({
					limit: listItemsLimit,
					date_time_from: firstDayFrom,
					order: "asc",
					sort: "utc_date_time",
				});

				if (bookingsList.data) {
					const tempDatesList = bookingsList.data.map(v => parseISOWrapper(v.date_time, DATE_FORMATS.DATE_FORMAT));

					const tempDatesSet = new Set(tempDatesList);
					// @ts-ignore
					setDatesList([...tempDatesSet]);
				}
			} catch (e) {
				handleAndNotify(e);
			}
		};

		createDatesList();

		// eslint-disable-next-line
	}, [currentMonth]);

	useEffect(() => {
		const loadBookings = async () => {
			try {
				const yesterday = new Date();
				yesterday.setDate(yesterday.getDate() - 1);

				const tomorrow = new Date();
				tomorrow.setDate(tomorrow.getDate() + 1);

				const todayFrom = getDateWithDefaultTime(new Date(), "FROM");
				const todayTo = getDateWithDefaultTime(new Date(), "TO");

				const bookingsListToday = await bookingsService.fetchBookings({
					limit: listItemsLimit,
					date_time_from: todayFrom,
					date_time_to: todayTo,
					order: "asc",
					sort: "utc_date_time",
				});

				if (bookingsListToday) {
					setResultsToday(bookingsListToday);
				}

				const pastTo = getDateWithDefaultTime(yesterday, "TO");
				const bookingsPast = await bookingsService.fetchBookings({
					limit: listItemsLimit,
					date_time_to: pastTo,
					order: "desc",
					sort: "utc_date_time",
				});

				if (bookingsPast) {
					setResultsPast(bookingsPast);
				}

				const futureFrom = getDateWithDefaultTime(tomorrow, "FROM");
				const bookingsFuture = await bookingsService.fetchBookings({
					limit: listItemsLimit,
					date_time_from: futureFrom,
					order: "asc",
					sort: "utc_date_time",
				});

				if (bookingsFuture) {
					setResultsFuture(bookingsFuture);
				}
			} catch (e) {
				handleAndNotify(e);
			} finally {
				setIsLoading(false);
			}
		};

		loadBookings();

		// eslint-disable-next-line
	}, [forceReload]);

	const getExperienceDetails = async id => {
		try {
			const expDetails = await experiencesService.getExperienceById(id);

			return expDetails;
		} catch (e) {
			handleAndNotify(e);
			return;
		}
	};

	const showBookingDetails = booking => {
		if (booking) {
			setSelectedBooking(booking);

			setShowSuperBookingDetails(true);

			const expDetails = getExperienceDetails(booking.eid);

			expDetails.then(v => {
				if (v) {
					// @ts-ignore
					setSelectedExperience(v);
				}
			});
		}
	};

	const handleCloseDetailsDialog = () => {
		setShowSuperBookingDetails(false);

		// To prevent quick moment with empty data on modal
		setTimeout(() => {
			setSelectedExperience(null);

			setSelectedBooking(null);
		}, 0);
	};

	const renderListOfResults = useCallback(
		(title: string, list: IBookingIId[]) => (
			<DashboardSeeAllList title={title} showDialog={showAllList} handleClose={() => setShowAllList(false)}>
				{list.map(v => (
					<DashboardItemList
						key={v.id}
						title={v?.exp_title || ""}
						expId={v.eid}
						localhostName={v.localhost_details?.first_name || t("YOUR_LOCALHOST_LABEL")}
						localhostId={v.localhost_id}
						avatarUrl={v.localhost_details?.photo_url || undefined}
						date={v.date_time}
						status={
							<Box display="inline" color={parseStatus(v.status).color}>
								{parseStatus(v.status).label}
							</Box>
						}
						seeDetailsOnClick={() => showBookingDetails(v)}
					/>
				))}
			</DashboardSeeAllList>
		),

		// eslint-disable-next-line
		[showAllList],
	);

	const rerenderDialogSeeAll = useCallback(() => {
		let list;
		switch (kindOfList) {
			case "PAST":
				list = renderListOfResults(`${t("MY_BOOKINGS.PAST_BOOKINGS_TITLE")} (${resultsPast.count})`, resultsPast.data);
				break;
			case "FUTURE":
				list = renderListOfResults(
					`${t("MY_BOOKINGS.FUTURE_BOOKINGS_TITLE")} (${resultsFuture.count})`,
					resultsFuture.data,
				);
				break;
			case "TODAY":
				list = renderListOfResults(
					`${t("MY_BOOKINGS.TODAYS_BOOKINGS_TITLE")} (${resultsToday.count})`,
					resultsToday.data,
				);
				break;
			case "SELECTED_DAY":
				list = renderListOfResults(
					`${t("MY_BOOKINGS.SELECTED_DAY_BOOKINGS_TITLE")} ${
						selectedDayCalendar && format(new Date(selectedDayCalendar as string), "dd MMMM yyyy")
					}`,
					resultsSelectedDay.data,
				);
				break;
			default:
				list = null;
		}
		return list;
		// eslint-disable-next-line
	}, [kindOfList, resultsFuture, resultsToday, resultsPast, t, showAllList, resultsSelectedDay, selectedDayCalendar]);

	const showAllListToday = (v: boolean) => {
		if (v) {
			setShowAllList(true);
			setKindOfList("TODAY");
		}
	};

	const showAllListPast = (v: boolean) => {
		if (v) {
			setShowAllList(true);
			setKindOfList("PAST");
		}
	};

	const showAllListFuture = (v: boolean) => {
		if (v) {
			setShowAllList(true);
			setKindOfList("FUTURE");
		}
	};

	const showAllListSelectedDay = (v: string | boolean) => {
		if (v) {
			setSelectedDayCalendar(v);
			setShowAllList(true);
			setKindOfList("SELECTED_DAY");
		}
	};

	return (
		<>
			<DashboardSeeDetailsTraveler
				id={selectedBooking?.id}
				avatarUrlLocalhost={selectedBooking?.localhost_details?.photo_url || undefined}
				title={selectedBooking?.exp_title || ""}
				startDate={selectedBooking?.date_time}
				startLocation={selectedExperience?.location?.start?.formatted_address || "-"}
				tickets={selectedBooking?.tickets}
				language={findNameByKey(selectedBooking?.language) || "-"}
				link={
					selectedExperience?.id
						? fillRoute(paths.SEARCH_EXPERIENCES, {
								title: slugify(selectedExperience.title.toLowerCase() || ""),
								id: selectedExperience.id,
						  })
						: ""
				}
				showDialog={showSuperBookingDetails && !!selectedBooking && !!selectedExperience}
				handleClose={handleCloseDetailsDialog}
				localhostLink={
					selectedBooking?.localhost_id &&
					selectedBooking?.localhost_details?.first_name &&
					fillRoute(paths.PUBLIC_PROFILE, {
						userName: encodeURI(selectedBooking.localhost_details.first_name.toLowerCase()),
						uid: selectedBooking.localhost_id,
					})
				}
				firstNameLocalhost={selectedBooking?.localhost_details?.first_name || null}
				phoneNumberLocalhost={selectedBooking?.localhost_details?.phone || null}
				mainPhoto={selectedExperience?.photo_main}
				experienceTime={selectedExperience?.duration_minutes}
				experienceTimeZone={selectedExperience?.location?.start?.timezone}
				comment={selectedBooking?.comments}
				status={selectedBooking?.status}
				statusLabel={
					selectedBooking?.status && (
						<Box display="inline" color={parseStatus(selectedBooking.status).color}>
							{parseStatus(selectedBooking.status).label}
						</Box>
					)
				}
				reload={setForceReload}
				cost={prices(
					selectedBooking?.price,
					selectedBooking?.currency !== "N/A" ? selectedBooking?.currency : undefined,
					undefined,
					undefined,
					true,
				)}
				downloadTicketButton
				rateBookingButton
				payButton
			/>

			{rerenderDialogSeeAll()}

			<Row>
				<ItemWrapper>
					<DashboardDetailsBox
						title={t("MY_BOOKINGS.TODAYS_BOOKINGS_TITLE")}
						titleAdditionalText={resultsToday.count > 0 ? resultsToday.count : undefined}
						emptyListMessage={
							resultsToday.data.length > 0 || isLoading ? undefined : t("MY_BOOKINGS.TODAYS_BOOKINGS_EMPTY_LIST")
						}
						seeAllOnClick={showAllListToday}
					>
						{isLoading ? (
							<Loader />
						) : (
							resultsToday.data?.slice(0, 5)?.map(v => (
								<DashboardItemList
									key={v.id}
									title={v?.exp_title || ""}
									expId={v.eid}
									avatarUrl={v.localhost_details?.photo_url || undefined}
									localhostName={v.localhost_details?.first_name || t("YOUR_LOCALHOST_LABEL")}
									localhostId={v.localhost_id}
									date={v.date_time}
									status={
										<Box display="inline" color={parseStatus(v.status).color}>
											{parseStatus(v.status).label}
										</Box>
									}
									seeDetailsOnClick={() => showBookingDetails(v)}
								/>
							))
						)}
					</DashboardDetailsBox>
				</ItemWrapper>

				<ItemWrapper>
					<Box display="flex" justifyContent="space-between" alignItems="center">
						<TitleSection>{t("MY_BOOKINGS.CALENDAR_TITLE")}</TitleSection>

						<Box display="flex" alignItems="center">
							<StyledBrightness1 />
							<Text>{t("MY_BOOKINGS.YOUR_PLANNED_BOOKINGS_LABEL")}</Text>
						</Box>
					</Box>

					<StyledDividerSection />

					<Box display="flex" alignItems="center" justifyContent="center" height="400px">
						<Calendar
							initialValue={new Date()}
							label=""
							onClickDate={showAllListSelectedDay}
							chosenDatesList={datesList}
							customDateFormat="yyyy-MM-dd"
							customBackgroundColorToday={colors.black}
							customFontColorToday={colors.white}
							onChangeMonth={v => setCurrentMonth(v)}
							onChangeYear={v => setCurrentYear(v)}
							width={isSmallScreen ? "auto" : "450px"}
							height={isSmallScreen ? "auto" : "350px"}
						/>
					</Box>
				</ItemWrapper>

				{/* @todo:backend - add a notifications*/}
				{/*<ItemWrapper width="33%">*/}
				{/*  <DashboardDetailsBox*/}
				{/*    title={t("MY_BOOKINGS.NOTIFICATIONS_TITLE")}*/}
				{/*    emptyListMessage={t("MY_BOOKINGS.NOTIFICATIONS_EMPTY_LIST")}*/}
				{/*    seeAllOnClick={v => console.log("v:", v)}*/}
				{/*  />*/}
				{/*</ItemWrapper>*/}
			</Row>

			<StyledDividerMain />

			<Row>
				<ItemWrapper>
					<DashboardDetailsBox
						title={t("MY_BOOKINGS.PAST_BOOKINGS_TITLE")}
						titleAdditionalText={resultsPast.count > 0 ? resultsPast.count : undefined}
						emptyListMessage={
							resultsPast.data.length > 0 || isLoading ? undefined : t("MY_BOOKINGS.PAST_BOOKINGS_EMPTY_LIST")
						}
						seeAllOnClick={showAllListPast}
					>
						{isLoading ? (
							<Loader />
						) : (
							resultsPast.data?.slice(0, 5)?.map(v => (
								<DashboardItemList
									key={v.id}
									title={v?.exp_title || ""}
									expId={v.eid}
									avatarUrl={v.localhost_details?.photo_url || undefined}
									localhostName={v.localhost_details?.first_name || t("YOUR_LOCALHOST_LABEL")}
									localhostId={v.localhost_id}
									date={v.date_time}
									status={
										<Box display="inline" color={parseStatus(v.status).color}>
											{parseStatus(v.status).label}
										</Box>
									}
									seeDetailsOnClick={() => showBookingDetails(v)}
								/>
							))
						)}
					</DashboardDetailsBox>
				</ItemWrapper>

				<ItemWrapper>
					<DashboardDetailsBox
						title={t("MY_BOOKINGS.FUTURE_BOOKINGS_TITLE")}
						titleAdditionalText={resultsFuture.count > 0 ? resultsFuture.count : undefined}
						emptyListMessage={
							resultsFuture.data.length > 0 || isLoading ? undefined : t("MY_BOOKINGS.FUTURE_BOOKINGS_EMPTY_LIST")
						}
						seeAllOnClick={showAllListFuture}
					>
						{isLoading ? (
							<Loader />
						) : (
							resultsFuture.data?.slice(0, 5)?.map(v => (
								<DashboardItemList
									key={v.id}
									title={v?.exp_title || ""}
									expId={v.eid}
									avatarUrl={v.localhost_details?.photo_url || undefined}
									localhostName={v.localhost_details?.first_name || t("YOUR_LOCALHOST_LABEL")}
									localhostId={v.localhost_id}
									date={v.date_time}
									status={
										<Box display="inline" color={parseStatus(v.status).color}>
											{parseStatus(v.status).label}
										</Box>
									}
									seeDetailsOnClick={() => showBookingDetails(v)}
								/>
							))
						)}
					</DashboardDetailsBox>
				</ItemWrapper>
			</Row>
		</>
	);
};

export default MyBookingsTraveler;
