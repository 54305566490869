import { Box, FormHelperText, Slider } from "@material-ui/core";
import { FormikProps } from "formik";
import { useCallback, useMemo, useState } from "react";

import { IExperience as FormValues } from "data/experiences/types";

import useTranslation from "hooks/useTranslation";

import MapMode from "components/creation-process/MapMode";
import StringArray from "components/creation-process/StringArray";
import StringCheckboxes from "components/creation-process/StringArrayCheckboxes";
import StringRadioGroup from "components/creation-process/StringRadioGroup";
import StringSwitches from "components/creation-process/StringSwitches";
import StringTags from "components/creation-process/StringTags";
import LanguageDefaultExp from "components/creation-process/tooltips/basic/LanguageDefaultExp";

import { Tooltip } from "ui/tooltips/Tooltip";

import {
	ItemDescription,
	ItemTitle,
	ItemWrapper,
	MainTitleSection,
	TitleTooltipWrapper,
	Wrapper,
} from "../shared.styled";

const MIN_VALUE = 1;
const MAX_VALUE = 15;

const marks = [
	{
		value: MIN_VALUE,
		label: MIN_VALUE,
	},
	{
		value: MAX_VALUE,
		label: MAX_VALUE,
	},
];

const Step2 = ({
	isSubmitting,
	values,
	errors,
	resetForm,
	isValid,
	handleSubmit,
	setFieldValue,
	validateForm,
	...othersProps
}: FormikProps<FormValues>) => {
	const { t } = useTranslation();
	const [sliderValue, setSliderValue] = useState<number[]>([
		values.number_of_travelers.min,
		values.number_of_travelers.max,
	]);

	const mapOnChange = useCallback(v => setFieldValue("location", { ...v }), [setFieldValue]);

	const mapValue = useMemo(
		() => values.location,
		// eslint-disable-next-line
		[values?.location?.start?.formatted_address, values?.location?.end?.formatted_address],
	);

	const handleChangeSlider = (_, v: number[]) => {
		const valueMin = v[0];
		const valueMax = v[1];
		if (valueMin >= valueMax || valueMax <= valueMin) {
			return;
		}

		setFieldValue("number_of_travelers.min", valueMin);
		setFieldValue("number_of_travelers.max", valueMax);
		setSliderValue(v);
	};

	const valueText = (v: number) => `${v}`;

	return (
		<Wrapper>
			<MainTitleSection>{t("EXPERIENCE.EXPERIENCE_DETAILS")}</MainTitleSection>

			<ItemWrapper>
				<ItemTitle>{t("EXPERIENCE.FIELDS.requirements.TITLE")}</ItemTitle>

				<ItemDescription>{t("EXPERIENCE.FIELDS.requirements.DESCRIPTION")}</ItemDescription>

				<StringArray onChange={v => setFieldValue("requirements", [...v])} value={values.requirements || []} />

				<FormHelperText error> {errors?.requirements}</FormHelperText>
			</ItemWrapper>

			<ItemWrapper>
				<ItemTitle>{t("EXPERIENCE.FIELDS.included.TITLE")}</ItemTitle>

				<ItemDescription>{t("EXPERIENCE.FIELDS.included.DESCRIPTION")}</ItemDescription>

				<StringTags
					onChange={v => setFieldValue("included", [...v])}
					value={values.included || []}
					field="INCLUDED"
					translateDic={false}
					add
				/>
				<FormHelperText error> {errors?.included}</FormHelperText>
			</ItemWrapper>

			<ItemWrapper>
				<ItemTitle>{t("EXPERIENCE.FIELDS.excluded.TITLE")}</ItemTitle>

				<ItemDescription>{t("EXPERIENCE.FIELDS.excluded.DESCRIPTION")}</ItemDescription>

				<StringTags
					onChange={v => setFieldValue("excluded", [...v])}
					value={values.excluded || []}
					field="EXCLUDED"
					translateDic={false}
					add
				/>
				<FormHelperText error> {errors?.excluded}</FormHelperText>
			</ItemWrapper>

			<ItemWrapper>
				<ItemTitle>{t("EXPERIENCE.FIELDS.number_of_travelers.TITLE")}</ItemTitle>

				<ItemDescription>{t("EXPERIENCE.FIELDS.number_of_travelers.DESCRIPTION")}</ItemDescription>

				<Box marginTop="50px">
					<Slider
						value={sliderValue}
						onChange={handleChangeSlider}
						valueLabelDisplay="on"
						aria-labelledby="number-travelers-range-slider"
						getAriaValueText={valueText}
						min={MIN_VALUE}
						max={MAX_VALUE}
						marks={marks}
					/>
				</Box>
			</ItemWrapper>

			<ItemWrapper>
				<ItemTitle>{t("EXPERIENCE.FIELDS.accessibility.TITLE")}</ItemTitle>

				<StringSwitches
					onChange={v => setFieldValue("accessibility", [...v])}
					value={values.accessibility || []}
					field="ACCESSIBILITY_TYPES"
				/>
			</ItemWrapper>

			<ItemWrapper>
				<ItemTitle>{t("EXPERIENCE.FIELDS.physical_difficulty_level.TITLE")}</ItemTitle>

				<StringRadioGroup
					onChange={v => setFieldValue("physical_difficulty_level", v)}
					value={values.physical_difficulty_level}
				/>
			</ItemWrapper>

			<ItemWrapper>
				<ItemTitle>{t("EXPERIENCE.FIELDS.health_restrictions.TITLE")}</ItemTitle>

				<StringCheckboxes
					onChange={v => setFieldValue("health_restrictions", [...v])}
					onChangeCustomArray={v => setFieldValue("custom_health_restrictions", [...v])}
					value={values.health_restrictions || []}
					customValue={values.custom_health_restrictions || undefined}
					field="HEALTH_RESTRICTIONS"
				/>
			</ItemWrapper>

			<ItemWrapper>
				<TitleTooltipWrapper>
					<ItemTitle>{t("EXPERIENCE.FIELDS.location.TITLE")}</ItemTitle>
					<Tooltip>
						<LanguageDefaultExp />
					</Tooltip>
				</TitleTooltipWrapper>
				<ItemDescription>{t("EXPERIENCE.FIELDS.location.DESCRIPTION")}</ItemDescription>

				<MapMode onChange={mapOnChange} value={mapValue} />

				{othersProps.touched.location &&
					(errors?.location?.start?.formatted_address || errors?.location?.end?.formatted_address) && (
						<FormHelperText error>{t("ERRORS.REQUIRED")}</FormHelperText>
					)}
			</ItemWrapper>
		</Wrapper>
	);
};

export default Step2;
